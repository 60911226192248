import { axiosBaseQuery } from '../../services/AxiosBaseQuery'
import { createApi } from '@reduxjs/toolkit/query/react'
import { IOrderSliceState } from './IOrderSliceState'
import Log from '../../services/Log'
import {
  CheckoutPayload,
  CopyOrderPayload,
  PayerAuthResponse,
} from '../../types/checkout'
import { CartPayload, ShippingChargesPayload } from '../../types/cart'
import {
  Cart,
  GetShipModes,
  IOrderDetails,
  OrderItem,
  OrderItemWithRoxProps,
  PaymentInstruction,
  ShippingChargesWithoutPromotions,
} from '../../types/order'
import { getCookieByName } from '../../utils/cookie'
import { ORDER_CONFIGS } from '../../configs/order'
import { ContactLensPayload } from '../../types/product'
import { isContactLensesProduct } from '../../utils/product'
import {
  fetchOrderItemsPrescriptionData,
  getOrderItemCatEntries,
  isClOrderItem,
} from '../../utils/order'
import { RootReducerState } from '../../redux/reducers'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react'

export const orderApi = createApi({
  reducerPath: 'orderApi',
  baseQuery: axiosBaseQuery({
    baseUrl: '/',
  }),
  tagTypes: ['ShipModes'],
  endpoints: (build) => ({
    getCart: build.query<
      {
        cart: Cart
        catentries?: IOrderSliceState['catentries']
        checkInventory: boolean
        filterPrescriptionItemType?: 'cl' | 'rx'
        filterPrescriptionNeededItems?: boolean
      },
      {
        storeId?: string
        body?: any
        checkInventory?: boolean
        filterPrescriptionItemType?: 'cl' | 'rx'
        filterPrescriptionNeededItems?: boolean
        fetchCatentries?: boolean
        fetchPrescriptionDetails?: boolean
        refetch?: boolean
        sessionId?: string | number
        currency?: string
      }
    >({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        try {
          const { storeId } = args
          const result = await fetchWithBQ({
            url: `store/${storeId}/cart/@self`,
            method: 'get',
            queryParams: args['queryParams'] || {},
            body: args.body || {},
          })

          const {
            checkInventory,
            filterPrescriptionItemType,
            filterPrescriptionNeededItems,
            fetchPrescriptionDetails,
          } = args
          let catentries: IOrderSliceState['catentries']
          let shippingInfo = undefined
          let updatedOrderItems: OrderItem[] | OrderItemWithRoxProps[] = []
          if (result) {
            const cart = result.data

            if (cart) {
              const orderItems = cart.orderItem
              if (orderItems && orderItems.length > 0) {
                let catentryIdList: string[] = []
                orderItems.forEach(async (item: OrderItem) => {
                  item.productId =
                    item.xitem_display_catentry_id ?? item.productId
                  catentryIdList.push(item.productId)
                })
                //get product info for all items
                updatedOrderItems = fetchPrescriptionDetails
                  ? await fetchOrderItemsPrescriptionData(
                      orderItems,
                      storeId || '',
                      cart.orderId
                    )
                  : orderItems

                if (catentryIdList.length > 0) {
                  catentries = await getOrderItemCatEntries(catentryIdList)
                }
              }
            }
          }

          if (catentries) {
            return {
              data: {
                cart: { ...result.data, orderItem: updatedOrderItems },
                catentries,
                checkInventory: checkInventory || false,
                shippingInfo,
                filterPrescriptionItemType,
                filterPrescriptionNeededItems:
                  filterPrescriptionNeededItems || false,
              },
            }
          }
          return {
            data: {
              cart: { ...result.data, orderItem: updatedOrderItems },
              checkInventory: checkInventory || false,
              shippingInfo,
              filterPrescriptionItemType,
              filterPrescriptionNeededItems:
                filterPrescriptionNeededItems || false,
              //orderPrescriptionDetails,
            },
          }
        } catch (error) {
          return { error: error }
        }
      },
    }),
    updateOrderItem: build.mutation<
      {
        orderItemId: string
      },
      CartPayload
    >({
      invalidatesTags: ['ShipModes'],
      async queryFn(args, queryApi, _extraOptions, fetchWithBQ) {
        const state = queryApi.getState() as RootReducerState
        const { storeID } = state.site.currentSite!
        const { userAgent } = window.navigator
        const forterToken = getCookieByName('forterToken')
        try {
          const result = await fetchWithBQ({
            url: `store/${storeID}/cart/@self/update_order_item`,
            method: 'put',
            body:
              {
                userAgent,
                forterToken,
                x_calculateOrder: ORDER_CONFIGS.calculateOrder,
                x_calculationUsage: ORDER_CONFIGS.calculationUsage,
                x_inventoryValidation: ORDER_CONFIGS.inventoryValidation,
                ...args.body,
              } || {},
          })
          return {
            data: {
              orderItemId: result.data.orderItem[0].orderItemId,
            },
          }
        } catch (error) {
          return { error: error }
        }
      },
    }),
    updateClOrderItem: build.query<
      {
        orderItemId: string
      },
      {
        storeId: string
        orderId: string
        items:
          | {
              x_contactLens: ContactLensPayload
              quantity: string
            }
          | {}[]
          | null
      }
    >({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { userAgent } = window.navigator
        const forterToken = getCookieByName('forterToken')
        try {
          const { storeId, orderId, items } = args
          const result = await fetchWithBQ({
            url: `store/${storeId}/cart/@self/update_order_item`,
            method: 'put',
            body:
              {
                userAgent,
                forterToken,
                orderId,
                x_calculateOrder: ORDER_CONFIGS.calculateOrder,
                x_calculationUsage: ORDER_CONFIGS.calculationUsage,
                x_inventoryValidation: ORDER_CONFIGS.inventoryValidation,
                orderItem: items,
              } || {},
          })
          return {
            data: {
              orderItemId: result.data.orderItem[0].orderItemId,
            },
          }
        } catch (error) {
          return { error: error }
        }
      },
    }),
    deleteOrderItem: build.mutation<
      {
        orderItemId: string
      },
      {
        orderItem: OrderItem
      }
    >({
      invalidatesTags: ['ShipModes'],
      async queryFn(args, queryApi, _extraOptions, fetchWithBQ) {
        const state = queryApi.getState() as RootReducerState
        const { storeID } = state.site.currentSite!
        const { userAgent } = window.navigator
        const forterToken = getCookieByName('forterToken')
        try {
          const { orderItem } = args
          const { orderItemId, roxableServices } = orderItem
          const payload: Record<string, string> = {}
          const isClAccessoriesOrder = isContactLensesProduct(orderItem)

          if (roxableServices) {
            payload.orderItemId_1 = roxableServices[0]?.orderItemId
            payload.orderItemId_2 = orderItemId
            payload.orderItemId_3 = roxableServices[1]?.orderItemId
            payload.orderItemId_4 = roxableServices[2]?.orderItemId
            payload.orderItemId_5 = roxableServices[3]?.orderItemId
          } else if (
            isClAccessoriesOrder ||
            isClOrderItem(orderItem.orderItemExtendAttribute)
          ) {
            if (!!orderItem.groupedOrderItemsId) {
              orderItem.groupedOrderItemsId.map((orderId, index) => {
                const position = `orderItemId_${index.toString()}`
                payload[position] = orderId
              })
            } else {
              payload.orderItemId = orderItemId
            }
          } else {
            payload.orderItemId = orderItemId
          }

          const result = await fetchWithBQ({
            url: `store/${storeID}/cart/@self/delete_order_item`,
            method: 'put',
            body:
              {
                userAgent,
                forterToken,
                x_calculateOrder: ORDER_CONFIGS.calculateOrder,
                x_calculationUsage: ORDER_CONFIGS.calculationUsage,
                ...payload,
              } || {},
          })
          return {
            data: {
              orderItemId: result.data.orderItem[0].orderItemId,
            },
          }
        } catch (error) {
          return { error: error }
        }
      },
    }),
    copyOrder: build.mutation<
      {
        orderItemId: string
      },
      CopyOrderPayload
    >({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { userAgent } = window.navigator
        const forterToken = getCookieByName('forterToken')
        try {
          const { storeId } = args
          const result = await fetchWithBQ({
            url: `store/${storeId}/cart/copy_order`,
            method: 'POST',
            body:
              {
                userAgent,
                forterToken,
                ...args,
              } || {},
          })
          return {
            data: {
              orderItemId: result.data.orderItem[0].orderItemId,
            },
          }
        } catch (error) {
          return { error: error }
        }
      },
    }),
    updateOrderShippingInfo: build.mutation<any, any>({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { userAgent } = window.navigator
        const forterToken = getCookieByName('forterToken')
        const { storeId } = args
        const result = await fetchWithBQ({
          url: `store/${storeId}/cart/@self/shipping_info`,
          method: 'PUT',
          body:
            {
              userAgent,
              forterToken,
              ...args.body,
            } || {},
        })

        return result?.data ? { data: result.data } : { error: result.error }
      },
    }),
    addPaymentInstruction: build.query<
      {
        orderId: string
        paymentInstruction: PaymentInstruction[]
      },
      CheckoutPayload
    >({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { userAgent } = window.navigator
        const forterToken = getCookieByName('forterToken')
        const webId = localStorage.getItem('webId')
        try {
          const { storeId } = args
          const result = await fetchWithBQ({
            url: `store/${storeId}/cart/@self/payment_instruction`,
            method: 'POST',
            body:
              {
                userAgent,
                forterToken,
                webId,
                ...args.body,
              } || {},
          })
          return {
            data: {
              orderId: result.data.orderId,
              paymentInstruction: result.data.paymentInstruction,
            },
          }
        } catch (error) {
          return { error: error }
        }
      },
    }),
    deleteAllPaymentInstructions: build.query<any, CheckoutPayload>({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { storeId } = args
        const { userAgent } = window.navigator
        const forterToken = getCookieByName('forterToken')
        const webId = localStorage.getItem('webId')
        const result = await fetchWithBQ({
          url: `store/${storeId}/cart/@self/payment_instruction`,
          method: 'DELETE',
          body:
            {
              userAgent,
              forterToken,
              webId,
              ...args.body,
            } || {},
        })
        return result?.data ? { data: result.data } : { error: result.error }
      },
    }),
    updatePaymentInstruction: build.query<
      {
        orderId: string
        paymentInstruction: PaymentInstruction[]
      },
      CheckoutPayload
    >({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { userAgent } = window.navigator
        const forterToken = getCookieByName('forterToken')
        const webId = localStorage.getItem('webId')
        try {
          const { storeId } = args
          const result = await fetchWithBQ({
            url: `store/${storeId}/cart/@self/payment_instruction`,
            method: 'PUT',
            body:
              {
                userAgent,
                forterToken,
                webId,
                ...args.body,
              } || {},
          })
          return {
            data: {
              orderId: result.data.orderId,
              paymentInstruction: result.data.paymentInstruction,
            },
          }
        } catch (error) {
          return { error: error }
        }
      },
    }),
    finalizeOrderWithCybersource: build.query<
      {
        orderComplete: boolean
        orderId: string
      },
      CheckoutPayload
    >({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { userAgent } = window.navigator
        const forterToken = getCookieByName('forterToken')
        const { storeId, body } = args
        await fetchWithBQ({
          url: `store/${storeId}/cart/@self/precheckout`,
          method: 'PUT',
          body: body || {},
        })
        const result = await fetchWithBQ({
          url: `store/${storeId}/cart/@self/checkout`,
          method: 'POST',
          body:
            {
              userAgent,
              forterToken,
              ...body,
            } || {},
        })

        return result?.data
          ? { data: { orderComplete: true, orderId: body?.orderId || '' } }
          : { error: result.error }
      },
    }),
    findOrderdById: build.query<
      {
        orderData: IOrderDetails
      },
      {
        orderId: string
        shouldFetchCatentries?: boolean
      } & Partial<CheckoutPayload>
    >({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { storeId, orderId, shouldFetchCatentries } = args
        //get product info for all items
        const result = await fetchWithBQ({
          url: `store/${storeId}/order/${orderId}`,
          method: 'GET',
          body:
            {
              ...args.body,
              profileName: 'LX_findItemByIds_Details',
            } || {},
        })

        if (result) {
          const updatedOrderItems = await fetchOrderItemsPrescriptionData(
            result.data?.orderItem as OrderItem[],
            storeId || '',
            result.data.orderId
          )
          if (shouldFetchCatentries) {
            let catentries: IOrderSliceState['catentries']

            let orderItemsProductId = (
              result.data?.orderItem as OrderItem[]
            )?.map((item) => item.productId)

            if (orderItemsProductId.length > 0) {
              catentries = await getOrderItemCatEntries(orderItemsProductId)
            }
            if (!!catentries) {
              return {
                data: {
                  orderData: { ...result.data, orderItem: updatedOrderItems },
                  catentries,
                },
              }
            }
          }
          return {
            data: {
              orderData: { ...result.data, orderItem: updatedOrderItems },
            },
          }
        }

        return { error: result.error }
      },
    }),
    setupPayerAuthentication: build.query<PayerAuthResponse, CheckoutPayload>({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { userAgent } = window.navigator
        const forterToken = getCookieByName('forterToken')
        const { storeId } = args
        const result = await fetchWithBQ({
          url: `store/${storeId}/payerAuthentication/paSetup/@self`,
          method: 'GET',
          body:
            {
              userAgent,
              forterToken,
              ...args.body,
            } || {},
        })

        return result?.data ? { data: result.data } : { error: result.error }
      },
    }),
    checkEnrollPayerAuthentication: build.mutation<
      PayerAuthResponse,
      CheckoutPayload
    >({
      async queryFn(args, queryApi, _extraOptions, fetchWithBQ) {
        const state = queryApi.getState() as RootReducerState
        const { storeID } = state.site.currentSite!
        const { userAgent, language } = window.navigator
        const { colorDepth, height, width } = window.screen
        const forterToken = getCookieByName('forterToken')

        const body = {
          userAgent,
          forterToken,
          billTo_httpBrowserJavaEnabled: window.navigator.javaEnabled()
            ? 'True'
            : 'False',
          payerAuthEnrollService_httpUserAccept: 'application/json',
          billTo_httpBrowserScreenHeight: height.toString() || '',
          billTo_httpBrowserScreenWidth: width.toString() || '',
          billTo_httpBrowserLanguage: language || '',
          billTo_httpBrowserColorDepth: colorDepth.toString() || '',
          billTo_httpBrowserTimeDifference:
            new Date().getTimezoneOffset().toString() || '',
          payerAuthEnrollService_httpUserAgent: userAgent || '',
          payerAuthEnrollService_deviceChannel: 'Browser',
          billTo_httpBrowserJavaScriptEnabled: 'True',
          ...args.body,
        }

        const result = await fetchWithBQ({
          url: `store/${storeID}/payerAuthentication/paCheckEnroll/@self`,
          method: 'POST',
          body,
        })

        return result.data
          ? { data: result.data as PayerAuthResponse }
          : { error: result.error as FetchBaseQueryError }
      },
    }),
    paypalExpressCheckStatus: build.query<any, CheckoutPayload>({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { userAgent } = window.navigator
        const forterToken = getCookieByName('forterToken')
        const { storeId } = args
        const result = await fetchWithBQ({
          url: `store/${storeId}/paypal/checkStatus/@self`,
          method: 'GET',
          body:
            {
              userAgent,
              forterToken,
              ...args.body,
            } || {},
        })

        return result?.data ? { data: result.data } : { error: result.error }
      },
    }),

    getShippingPrice: build.query<
      { shipList: ShippingChargesWithoutPromotions[] },
      ShippingChargesPayload
    >({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { storeId, orderId } = args
        try {
          const result = await fetchWithBQ({
            url: `store/${storeId}/cart/orderId/${orderId}/shipCharges`,
            method: 'GET',
          })
          return result?.data && result.data.ships.shipList.length
            ? { data: result.data.ships }
            : { error: result.error }
        } catch (e) {
          Log.error('Could not retrieve shipping price')
          return {
            error: e,
          }
        }
      },
    }),
    getShipModes: build.query<
      GetShipModes[],
      { orderId?: string; orderItem?: OrderItem[] }
    >({
      providesTags: ['ShipModes'],
      async queryFn(args, api, _extraOptions, fetchWithBQ) {
        const { userAgent } = window.navigator
        const forterToken = getCookieByName('forterToken')
        const state = api.getState() as RootReducerState
        const { storeID } = state.site.currentSite!
        const { orderId, orderItem } = args

        if (!orderId || !orderItem?.length) {
          return {
            data: [],
          }
        }

        const result = await fetchWithBQ({
          url: `store/${storeID}/ShipModes/GetShipModes`,
          method: 'GET',
          queryParams: {
            orderId,
          },
          body:
            {
              userAgent,
              forterToken,
            } || {},
        })

        return result.data
          ? { data: result.data.usableShippingMode as GetShipModes[] }
          : { error: result.error as FetchBaseQueryError }
      },
    }),
  }),
})

export const {
  useGetCartQuery,
  useLazyGetCartQuery,
  useFindOrderdByIdQuery,
  useLazyFindOrderdByIdQuery,
  useDeleteOrderItemMutation,
  useUpdateOrderItemMutation,
  useGetShipModesQuery,
  useCheckEnrollPayerAuthenticationMutation,
} = orderApi
